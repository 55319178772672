import { render, staticRenderFns } from "./SpcmMilestonesAdd.vue?vue&type=template&id=968dbd30&scoped=true"
import script from "./SpcmMilestonesAdd.vue?vue&type=script&lang=js"
export * from "./SpcmMilestonesAdd.vue?vue&type=script&lang=js"
import style0 from "./SpcmMilestonesAdd.vue?vue&type=style&index=0&id=968dbd30&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "968dbd30",
  null
  
)

export default component.exports